<template>
  <v-container class="mt-10">
    <section name="content" class="text-page-border">
  <template>
    <v-card>
      <v-tabs v-model="tab" fixed-tabs>
        <v-tab :key="0">{{ $t("patient-information.patient-info-tab-data-usage") }}</v-tab>
        <v-tab :key="1">{{ $t("patient-information.patient-info-tab-bio-samples") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :value="0">
          <v-card flat>
            <v-card-text>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-data-usage-header-1") }}</h3>
              <p class="mt-3">
                {{ $t("patient-information.patient-info-tab-data-usage-content-1") }}
              </p>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-data-usage-header-2") }}</h3>
              <p class="mt-3">
                {{ $t("patient-information.patient-info-tab-data-usage-content-2") }}
              </p>
              <ul class="mt-3 ml-5">
                <li><b>{{ $t("patient-information.patient-info-tab-data-usage-content-2-li-1-bold") }}</b>{{ $t("patient-information.patient-info-tab-data-usage-content-2-li-1") }}</li>
                <li><b>{{ $t("patient-information.patient-info-tab-data-usage-content-2-li-2-bold") }}</b>{{ $t("patient-information.patient-info-tab-data-usage-content-2-li-2") }}</li>
              </ul>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-data-usage-header-3") }}</h3>
              <p class="mt-3">{{ $t("patient-information.patient-info-tab-data-usage-content-3") }} <br> <br>{{ $t("patient-information.patient-info-tab-data-usage-content-3-2") }} </p>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-data-usage-header-4") }}</h3>
              <ul class="mt-3 ml-5">
                <li><b>{{ $t("patient-information.patient-info-tab-data-usage-content-4-li-1-bold") }}</b>{{ $t("patient-information.patient-info-tab-data-usage-content-4-li-1") }}</li>
                <li><b>{{ $t("patient-information.patient-info-tab-data-usage-content-4-li-2-bold") }}</b>{{ $t("patient-information.patient-info-tab-data-usage-content-4-li-2") }}</li>
                <li><b>{{ $t("patient-information.patient-info-tab-data-usage-content-4-li-3-bold") }}</b>{{ $t("patient-information.patient-info-tab-data-usage-content-4-li-3") }}</li>
              </ul>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-data-usage-header-5") }}</h3>
              <p class="mt-3">{{ $t("patient-information.patient-info-tab-data-usage-content-5") }}</p>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-data-usage-header-6") }}</h3>
              <p class="mt-3">{{ $t("patient-information.patient-info-tab-data-usage-content-6") }}</p>
              <ul class="mt-3 ml-5">
                <li><b>{{ $t("patient-information.patient-info-tab-data-usage-content-6-li-1-bold") }}</b>{{ $t("patient-information.patient-info-tab-data-usage-content-6-li-1") }}</li>
                <li><b>{{ $t("patient-information.patient-info-tab-data-usage-content-6-li-2-bold") }}</b>{{ $t("patient-information.patient-info-tab-data-usage-content-6-li-2") }}</li>
                <li><b>{{ $t("patient-information.patient-info-tab-data-usage-content-6-li-3-bold") }}</b>{{ $t("patient-information.patient-info-tab-data-usage-content-6-li-3") }}</li>
              </ul>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-data-usage-header-7") }}</h3>
              <p class="mt-3">{{ $t("patient-information.patient-info-tab-data-usage-content-7") }} <br></p>
              <p class="mt-3"><em>{{ $t("patient-information.patient-info-tab-data-usage-header-7-3") }}</em></p>
              <ul class="mt-3 ml-5">
                <li><a href="https://forschen-fuer-gesundheit.de/projektregister/">https://forschen-fuer-gesundheit.de/projektregister/</a></li>
              </ul>
              <br>
              <p class="mt-3"><em>{{ $t("patient-information.patient-info-tab-data-usage-content-7-2") }}</em></p>
              <ul class="mt-3 ml-5">
                <li><a href="https://www.medizininformatik-initiative.de/">{{ $t("patient-information.patient-info-tab-data-usage-content-7-link-1") }}</a></li>
                <li><a href="https://www.youtube.com/watch?v=t9H-YnWQe2k">{{ $t("patient-information.patient-info-tab-data-usage-content-7-link-2") }}</a></li>
                <li><a href="https://www.medizininformatik-initiative.de/de/deutsches-forschungsdatenportal-fuer-gesundheit">{{ $t("patient-information.patient-info-tab-data-usage-content-7-link-3") }}</a></li>
              </ul>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="1">
          <v-card flat>
            <v-card-text>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-biobank-header-1") }}</h3>
              <p class="mt-3">
                {{ $t("patient-information.patient-info-tab-biobank-content-1") }}
              </p>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-biobank-header-2") }}</h3>
              <p class="mt-3">
                {{ $t("patient-information.patient-info-tab-biobank-content-2") }}
              </p>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-biobank-header-3") }}</h3>
              <p class="mt-3">
                {{ $t("patient-information.patient-info-tab-biobank-content-3") }}
              </p>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-biobank-header-4") }}</h3>
              <ul class="mt-3 ml-5">
                <li><b>{{ $t("patient-information.patient-info-tab-biobank-content-4-li-1-bold") }}</b>{{ $t("patient-information.patient-info-tab-biobank-content-4-li-1") }}</li>
                <li><b>{{ $t("patient-information.patient-info-tab-biobank-content-4-li-2-bold") }}</b>{{ $t("patient-information.patient-info-tab-biobank-content-4-li-2") }}</li>
                  <li><b>{{ $t("patient-information.patient-info-tab-biobank-content-4-li-3-bold") }}</b>{{ $t("patient-information.patient-info-tab-biobank-content-4-li-3") }}</li>
              </ul>
              <h3 class="mt-5">{{ $t("patient-information.patient-info-tab-biobank-header-5") }}</h3>
              <p class="mt-3">
                <em>{{ $t("patient-information.patient-info-tab-biobank-content-5-1") }}</em>
              </p>
              <ul class="mt-3 ml-5">
                <li><a href="https://www.uni-giessen.de/de/fbz/fb11/forschung/projekte">https://www.uni-giessen.de/de/fbz/fb11/forschung/projekte</a></li>
              </ul>
              <br>
              <p class="mt-3">
                <em>{{ $t("patient-information.patient-info-tab-biobank-content-5-2-1") }}</em>
              </p>
              <ul class="mt-3 ml-5">
                <li><a href="https://dzl.de/patienten/proben-und-datenverwendung/">https://dzl.de/patienten/proben-und-datenverwendung/</a></li>
              </ul>
              <br>
              <p class="mt-3"><em>{{ $t("patient-information.patient-info-tab-biobank-content-5-2") }}</em></p>
              <ul class="mt-3 ml-5">
                <li><a href="https://www.youtube.com/watch?v=t9H-YnWQe2k">{{ $t("patient-information.patient-info-tab-data-usage-content-7-link-2") }}</a></li>
                <li><a href="https://www.biobanken-verstehen.de/">{{ $t("patient-information.patient-info-tab-biobank-content-5-link-biobank") }}</a></li>
                <li><a href="/docs/Positionspapier_Biobanking-und-Partizipation_2024.pdf" download>{{ $t("patient-information.patient-info-tab-biobank-content-position-paper") }}</a></li>
              </ul>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <download-card
      :title="$t('patient-information.patient-info-brochure-title')"
      version="V4.8"
      releaseDate="21.01.2025"
      href="/docs/2025-01-21_Broad-IC_FB11-Biobank_V4-8.pdf"
    >
    </download-card>
  </template>
  </section>
  </v-container>
</template>
<style lang="scss" scoped>
@import "~vuetify/src/styles/settings/_variables";
.videoWrapper {
  position: relative;
  padding-bottom: 49.25%;
  padding-top: 2.5rem;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding-top: 1rem;
  }
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  height: 80%;
}
</style>
<script>
export default {
  data() {
    return {
      tab: 0,
    };
  },
};
</script>